const photos = [
  {
    name: "Academy",
    image: require("../../../Img/skare/academy.jpg")
  },
  {
    name: "Cam Borozon",
    image: require("../../../Img/skare/cam-borozon.jpg")
  },
  {
    name: "Carbonium",
    image: require("../../../Img/skare/carbonium.jpg")
  },
  {
    name: "Extreme Efilir",
    image: require("../../../Img/skare/extreme-efilir.png")
  },
  {
    name: "Full Quality",
    image: require("../../../Img/skare/full-quality.jpg")
  },
  {
    name: "Glamour People",
    image: require("../../../Img/skare/glamour-people.jpg")
  },
  {
    name: "Lavinia",
    image: require("../../../Img/skare/lavinia.jpg")
  },
  {
    name: "Lavinia Efilir",
    image: require("../../../Img/skare/lavinia-efilir.jpg")
  },
  {
    name: "Oriente",
    image: require("../../../Img/skare/oriente.jpg")
  },
  {
    name: "Star",
    image: require("../../../Img/skare/star.jpg")
  },
  {
    name: "Strategy",
    image: require("../../../Img/skare/strategy.jpg")
  },
  {
    name: "Trendy",
    image: require("../../../Img/skare/trendy.jpg")
  },
  {
    name: "Xtreme",
    image: require("../../../Img/skare/xtreme.png")
  },

  {
    name: "Silkcut",
    image: require("../../../Img/skare/silkcut.png")
  },
  {
    name: "Silkcut Rainbow",
    image: require("../../../Img/skare/silkcut-rainbow.png")
  }
];

module.exports = photos;
