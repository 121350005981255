const photos = [
  {
    name: "Astral",
    image: require("../../../Img/bazeni/Astral.jpg")
  },
  {
    name: "Glam",
    image: require("../../../Img/bazeni/Glam.jpg")
  },
  {
    name: "Hug",
    image: require("../../../Img/bazeni/Hug.jpg")
  },
  {
    name: "Jolly",
    image: require("../../../Img/bazeni/Jolly.jpg")
  },
  {
    name: "Luna",
    image: require("../../../Img/bazeni/Luna.jpg")
  },
  {
    name: "Polaris",
    image: require("../../../Img/bazeni/Polaris.jpg")
  }
];

module.exports = photos;
