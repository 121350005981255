import React from "react";

const renderPhotosList = photos => {
  const photoList = photos.map(photo => {
    const productUrl = window.location.href.split("/").pop(); // the last param from URL
    let imgClass;

    if (productUrl === "bazeni" || productUrl === "haube") {
      imgClass = "img-center product";
    } else {
      imgClass = "img-center product product-img-auto";
    }

    return (
      <div
        className="col-12 col-sm-6 col-md-4 col-lg-3 product-box"
        key={photo.name}
      >
        <img className={imgClass} src={photo.image} alt={photo.name} />
        <p className="product-name">{photo.name}</p>
      </div>
    );
  });

  return photoList;
};

export default renderPhotosList;
