const photos = [
  {
    name: "Jaguar CM2000",
    image: require("../../../Img/masinice/jaguar-cm2000.jpg")
  },
  {
    name: "Moser",
    image: require("../../../Img/masinice/Moser.jpg")
  },
  {
    name: "Moser 1400",
    image: require("../../../Img/masinice/Moser-1400.jpg")
  },
  {
    name: "Moser ChroMini",
    image: require("../../../Img/masinice/Moser-ChroMini.jpg")
  },
  {
    name: "Moser ChromStyle Pro",
    image: require("../../../Img/masinice/Moser-ChromStyle-pro.png")
  },
  {
    name: "Moser Class45",
    image: require("../../../Img/masinice/Moser-class45.png")
  },
  {
    name: "Moser Genio",
    image: require("../../../Img/masinice/Moser-genio.jpg")
  },
  {
    name: "Moser Genio Plus",
    image: require("../../../Img/masinice/Moser-genio-plus.png")
  },
  {
    name: "Moser Li Pro",
    image: require("../../../Img/masinice/Moser-li-pro.jpg")
  },
  {
    name: "Moser Primat",
    image: require("../../../Img/masinice/moser-primat.png")
  },
  {
    name: "Wahl Magic Clip",
    image: require("../../../Img/masinice/Wahl-magic-clip.jpg")
  },
  {
    name: "Moser T-cut",
    image: require("../../../Img/masinice/moser-t-cut.jpg")
  },
  {
    name: "Wahl Taper 2000",
    image: require("../../../Img/masinice/wahl-taper-2000.jpg")
  }
];

module.exports = photos;
