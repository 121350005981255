import React, { Fragment } from "react";

import "./Manufacturer.scss";

const Manufacturer = props => {
  return (
    <Fragment>
      <img className="img-center logo mt-5" src={props.img} alt={props.alt} />
      <p className="manufacturer-title">{props.title}</p>
      <small>{props.products}</small>
    </Fragment>
  );
};

export default Manufacturer;
