import React, { Fragment } from "react";

import { Container, Row, Col } from "reactstrap";

const MainMargins = {
  marginTop: "80px",
  marginBottom: "80px"
};

const NotFound = () => {
  return (
    <Fragment>
      <Container>
        <Row>
          <Col className="text-center" style={MainMargins}>
            <h2 className="mb-4">Stranica nažalost nije pronađena.</h2>
            <a href="/" className="mt-4">
              <i class="far fa-hand-point-left" /> Povratak na početnu stranicu.
            </a>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NotFound;
