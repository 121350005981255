const photos = [
  {
    name: "Pregača (crna)",
    image: require("../../../Img/pregace/black.png")
  },
  {
    name: "Ogrtač (crna)",
    image: require("../../../Img/pregace/black-2.png")
  },
  {
    name: "Pregača (ciklama)",
    image: require("../../../Img/pregace/ciklama.png")
  },
  {
    name: "Ogrtač (ciklama)",
    image: require("../../../Img/pregace/ciklama-2.png")
  },
  {
    name: "Pregača (petrolej)",
    image: require("../../../Img/pregace/petrolej.png")
  },
  {
    name: "Ogrtač (petrolej)",
    image: require("../../../Img/pregace/petrolej-2.png")
  }
];

module.exports = photos;
