import React from "react";

import HeadTags from '../../Head/Tags';
import "../Product/Product.scss";

import photos from "./photos";
import renderPhotosList from "../renderPhotosList";

const Skare = () => {
  // set const to photos list div
  const photoList = renderPhotosList(photos);

  return (
    <>
      <HeadTags title='Škare | Frizer Pribor' />
      <section>
        <div className="container">
          <div className="row">{photoList}</div>
        </div>
      </section>
    </>
  );
};

export default Skare;
