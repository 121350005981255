const photos = [
  {
    name: "Razne boje za kosu",
    image: require("../../../Img/ostalo/boje-za-kosu.jpg")
  },
  {
    name: "Razne boje za kosu (Crazy color)",
    image: require("../../../Img/ostalo/crazy-color.jpg")
  },
  {
    name: "Bamboo",
    image: require("../../../Img/ostalo/bamboo.png")
  },
  {
    name: "Carbon",
    image: require("../../../Img/ostalo/carbon.png")
  },
  {
    name: "Difuzor",
    image: require("../../../Img/ostalo/Difuzor.jpg")
  },
  {
    name: "Držač fena",
    image: require("../../../Img/ostalo/drzac-fena.jpg")
  },
  {
    name: "Mješalica",
    image: require("../../../Img/ostalo/mjesalica.jpg")
  },
  {
    name: "Ogledalo",
    image: require("../../../Img/ostalo/ogledalo.jpg")
  },
  {
    name: "Sterilizator",
    image: require("../../../Img/ostalo/Sterilizator.jpg")
  },
  {
    name: "Torbica 1",
    image: require("../../../Img/ostalo/torbica-1.png")
  },
  {
    name: "Torbica 2",
    image: require("../../../Img/ostalo/torbica-2.png")
  },
  {
    name: "Torbica 3",
    image: require("../../../Img/ostalo/torbica-3.png")
  },
  {
    name: "Vikler 1",
    image: require("../../../Img/ostalo/vikler-1.png")
  },
  {
    name: "Vikler 2",
    image: require("../../../Img/ostalo/vikler-2.png")
  },
  {
    name: "Vikler 3",
    image: require("../../../Img/ostalo/vikler-3.png")
  }
];

module.exports = photos;
