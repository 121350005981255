import React from "react";

import "./ColoredBox.scss";

const ColoredBox = props => {
  return (
    <section className="coloredbox">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="coloredbox-text">{props.text}</h1>
            {props.buttonText ? (
              <p className="btn btn-coloredbox">{props.buttonText}</p>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ColoredBox;
