const photos = [
  {
    name: "Brio",
    image: require("../../../Img/haube/Brio.jpg")
  },
  {
    name: "Rondo",
    image: require("../../../Img/haube/Rondo.jpg")
  },
  {
    name: "Soffio",
    image: require("../../../Img/haube/Soffio.jpg")
  }
];

module.exports = photos;
