import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import "./About.scss";

import HeadTags from '../Head/Tags';
import ColoredBox from "../ColoredBox/ColoredBox";

const About = () => {
  return (
    <Fragment>
      <HeadTags title="O nama | Frizer Pribor" />
      <section>
        <div className="container">
          <h1 className="category-name">
            <span className="b-purple-line">O nama</span>
          </h1>
          <div className="row">
            <div className="col-md-12">
              <h1 className="ready-work pb-3">Frizer Pribor z.z.</h1>
            </div>
            <div className="col-md-4">
              <p>
                <i className="fas fa-map-marker-alt" /> Zagreb, Croatia
              </p>
              <p>
                Dragutina Domjanića 9 <br /> 10000 Zagreb, Croatia
              </p>
            </div>
            <div className="col-md-4">
              <p>
                <i className="far fa-clock" /> Radno vrijeme
              </p>
              <p>
                Ponedjeljak - Petak <br /> 08 - 20 h
              </p>

              <p>
                Subota <br /> 08 - 14 h
              </p>
            </div>
            <div className="col-md-4">
              <p>
                <small>Mob: </small>
                <a href="tel:+385914664532">+385 91 4664 532</a>
              </p>
              <p>
                <small>Tel: </small>
                <a href="tel:+38514664532">+385 1 4664 532</a>
              </p>
              <p>
                <small>Fax: </small>
                <a href="tel:+38514664523">+385 1 4664 523</a>
              </p>
              <a href="mailto:frizer.pribor@gmail.com">
                frizer.pribor@gmail.com
              </a>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2780.774149780559!2d15.994640215940935!3d45.81578137910676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d7adcd1be285%3A0x1a6fa8f864bc3462!2sUl.+Dragutina+Domjani%C4%87a+9%2C+10000%2C+Zagreb!5e0!3m2!1shr!2shr!4v1549361825479"
                title="Gdje se nalazimo?"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </section>
      <Link to="/proizvodi">
        <ColoredBox
          className="text-center"
          text="Zainteresirani ste za naše proizvode?"
          buttonText="Pogledaj"
        />
      </Link>
    </Fragment>
  );
};

export default About;
