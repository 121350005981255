import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import "./IndexPage.scss";

import HeadTags from '../Head/Tags';
import ColoredBox from "../ColoredBox/ColoredBox";
import IndexContent from "./IndexContent/IndexContent";

class IndexPage extends Component {
  render() {
    return (
      <Fragment>
        <HeadTags title="Frizerski pribor i oprema | Frizer Pribor" />
        <header className="header-image" />
        <IndexContent />
        <Link to="/proizvodi">
          <ColoredBox
            className="text-center"
            text="Zainteresirani ste za naše proizvode?"
            buttonText="Pogledaj"
          />
        </Link>
      </Fragment>
    );
  }
}

export default IndexPage;
