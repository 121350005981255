import React from 'react';
import { Helmet } from 'react-helmet';

const Tags = props => {
    return (
        <Helmet>
            <title>{props.title}</title>
            {props.children}
        </Helmet>
    )
}

export default Tags;