const photos = [
  {
    name: "Ermila Compact",
    image: require("../../../Img/fenovi/ermila-compact.png")
  },
  {
    name: "Ermila Speed",
    image: require("../../../Img/fenovi/ermila-speed.png")
  },
  {
    name: "Furious",
    image: require("../../../Img/fenovi/furious.jpg")
  },
  {
    name: "Jaguar HD Amico",
    image: require("../../../Img/fenovi/jaguar-hdamico.png")
  },
  {
    name: "Moser Pro",
    image: require("../../../Img/fenovi/Moser-pro.jpg")
  },
  {
    name: "NEO 3830",
    image: require("../../../Img/fenovi/NEO3830.jpg")
  },
  {
    name: "NEO 3840",
    image: require("../../../Img/fenovi/NEO3840.jpg")
  },
  {
    name: "Ventus",
    image: require("../../../Img/fenovi/ventus.png")
  }
];

module.exports = photos;
