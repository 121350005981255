import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Redirect,
  Switch
} from "react-router-dom";

import "./Navigation.scss";

import IndexPage from "../IndexPage/IndexPage";
import Products from "../Products/Products";
import About from "../About/About";
import Manufacturers from "../Manufacturers/Manufacturers";
import NotFound from "../NotFound/NotFound";

import Bazeni from "../Products/Bazeni/Bazeni";
import Cetke from "../Products/Cetke/Cetke";
import Fenovi from "../Products/Fenovi/Fenovi";
import Haube from "../Products/Haube/Haube";
import Kolica from "../Products/Kolica/Kolica";
import Masinice from "../Products/Masinice/Masinice";
import Ostalo from "../Products/Ostalo/Ostalo";
import Pregace from "../Products/Pregace/Pregace";
import Stolice from "../Products/Stolice/Stolice";
import Skare from "../Products/Skare/Skare";

const Navigation = () => {
  let contactRedirect;

  if (window.location.href.indexOf("kontakt.php") > -1) {
    contactRedirect = <Redirect to="/o-nama" />;
  }

  return (
    <Router>
      <Fragment>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <NavLink to="/" className="navbar-brand">
              Frizer Pribor
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <NavLink to="/" className="nav-link" exact>
                    Početna
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/o-nama" className="nav-link" exact>
                    O nama
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/proizvodi" className="nav-link" exact>
                    Proizvodi
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/proizvodaci" className="nav-link" exact>
                    Proizvođači
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Switch>
          <Route exact strict path="/" component={IndexPage} />
          <Route exact strict path="/o-nama" component={About} />
          <Route exact strict path="/proizvodi" component={Products} />
          <Route exact strict path="/proizvodi/bazeni" component={Bazeni} />
          <Route exact strict path="/proizvodi/cetke" component={Cetke} />
          <Route exact strict path="/proizvodi/fenovi" component={Fenovi} />
          <Route exact strict path="/proizvodi/haube" component={Haube} />
          <Route exact strict path="/proizvodi/kolica" component={Kolica} />
          <Route exact strict path="/proizvodi/masinice" component={Masinice} />
          <Route exact strict path="/proizvodi/ostalo" component={Ostalo} />
          <Route exact strict path="/proizvodi/pregace" component={Pregace} />
          <Route exact strict path="/proizvodi/stolice" component={Stolice} />
          <Route exact strict path="/proizvodi/skare" component={Skare} />
          <Route exact strict path="/proizvodaci" component={Manufacturers} />
          <Route path="*" component={NotFound} />
        </Switch>

        {/* redirect if url is '/kontatk.php' which is good positioned on google */}
        {contactRedirect}
      </Fragment>
    </Router>
  );
};

export default Navigation;
