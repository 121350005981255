import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import HeadTags from '../Head/Tags';
import Product from "./Product/Product";

import bazeniDefault from "../../Img/proizvodi-naslovne/bazeni-default.jpg";
import cetkeDefault from "../../Img/proizvodi-naslovne/cetke-default.jpg";
import fenoviDefault from "../../Img/proizvodi-naslovne/fenovi-default.jpg";
import haubeDefault from "../../Img/proizvodi-naslovne/haube-default.jpg";
import kolicaDefault from "../../Img/proizvodi-naslovne/kolica-default.jpg";
import masiniceDefault from "../../Img/proizvodi-naslovne/masinice-default.jpg";
import pregaceDefault from "../../Img/proizvodi-naslovne/pregace-default.png";
import stoliceDefault from "../../Img/proizvodi-naslovne/stolice-default.jpg";
import skareDefault from "../../Img/proizvodi-naslovne/skare-default.jpg";
import ostaloDefault from "../../Img/proizvodi-naslovne/ostalo-default.png";

const Products = () => {
  return (
    <Fragment>
      <HeadTags title="Proizvodi | Frizer Pribor" />
      <section>
        <div className="container text-center">
          <h1 className="category-name">
            <span className="b-purple-line">Proizvodi</span>
          </h1>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-box">
              <Link to="/proizvodi/bazeni">
                <Product name="Bazeni" img={bazeniDefault} />
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-box">
              <Link to="/proizvodi/cetke">
                <Product name="Četke" img={cetkeDefault} />
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-box">
              <Link to="/proizvodi/fenovi">
                <Product name="Fenovi" img={fenoviDefault} />
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-box">
              <Link to="/proizvodi/haube">
                <Product name="Haube" img={haubeDefault} />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-box">
              <Link to="/proizvodi/kolica">
                <Product name="Kolica" img={kolicaDefault} />
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-box">
              <Link to="/proizvodi/masinice">
                <Product name="Mašinice" img={masiniceDefault} />
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-box">
              <Link to="/proizvodi/pregace">
                <Product name="Pregače" img={pregaceDefault} />
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-box">
              <Link to="/proizvodi/stolice">
                <Product name="Stolice" img={stoliceDefault} />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-box">
              <Link to="/proizvodi/skare">
                <Product name="Škare" img={skareDefault} />
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-box">
              <Link to="/proizvodi/ostalo">
                <Product name="Ostalo" img={ostaloDefault} />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Products;
