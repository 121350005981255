import React, { Component, Fragment } from "react";
import "./App.scss";

import Main from "./Containers/Main";

import ReactGA from 'react-ga';
ReactGA.initialize('UA-143606762-1');
ReactGA.pageview("/");
ReactGA.pageview("/o-nama");
ReactGA.pageview("/proizvodi");
ReactGA.pageview("/proizvodi/bazeni");
ReactGA.pageview("/proizvodi/cetke");
ReactGA.pageview("/proizvodi/fenovi");
ReactGA.pageview("/proizvodi/haube");
ReactGA.pageview("/proizvodi/kolica");
ReactGA.pageview("/proizvodi/masinice");
ReactGA.pageview("/proizvodi/ostalo");
ReactGA.pageview("/proizvodi/pregace");
ReactGA.pageview("/proizvodi/stolice");
ReactGA.pageview("/proizvodi/skare");
ReactGA.pageview("/proizvodaci");

class App extends Component {
  render() {
    return (
      <Fragment>
        <Main />
      </Fragment>
    );
  }
}

export default App;
