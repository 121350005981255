import React, { Fragment } from "react";

const helpingSection = {
  margin: "0",
  padding: "50px 0"
};

const IndexContent = () => {
  return (
    <Fragment>
      <section>
        <div className="container mt-4 mb-4">
          <div className="row">
            <div className="col-md-4">
              <h3 className="text-uppercase">
                <strong>
                  Tradicija duga <br /> 80 godina.
                </strong>
              </h3>
            </div>
            <div className="col-md-6 offset-md-2">
              <p>Frizer-pribor zanatska zadruga osnovana je 1940. godine.</p>
              <p>
                Siguran temelj naše zadruge na tržištu je dugogodišnje iskustvo
                i rad sa frizerima, frizerskom opremom i proizvodima. <br />{" "}
                Osnovni motiv i pokretač nam je zadovoljstvo naših klijenata,
                stoga se trudimo udovoljiti Vašim potrebama i zahtjevima
                najbolje što znamo i možemo.
              </p>
              <p>
                Namještaj, aparate, proizvode i sve ostalo možete vidjeti i
                kupiti u našem dućanu.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section style={helpingSection}>
        <div className="container text-center">
          <div className="row">
            <div className="col-sm-6 col-md-4 index-success-exp">
              <i className="fas fa-question fa-3x" />
              <p className="mt-4 text-uppercase">
                Odgovaramo na <br /> Vaša pitanja
              </p>
            </div>
            <div className="col-sm-6 col-md-4 index-success-exp">
              <i className="fas fa-hands-helping fa-3x" />
              <p className="mt-4 text-uppercase">
                Pomažemo <br /> pri odabiru
              </p>
            </div>
            <div className="col-sm-6 col-md-4 index-success-exp">
              <i className="fas fa-check fa-3x" />
              <p className="mt-4 text-uppercase">
                Ostvarujemo <br /> Vaše želje
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default IndexContent;
