const photos = [
  {
    name: "Blend",
    image: require("../../../Img/cetke/blend.png")
  },
  {
    name: "Ceramic Ion",
    image: require("../../../Img/cetke/ceramic-ion.png")
  },
  {
    name: "Divine",
    image: require("../../../Img/cetke/divine.png")
  },
  {
    name: "Duo",
    image: require("../../../Img/cetke/duo.png")
  },
  {
    name: "Ceramic Ion Gold 1",
    image: require("../../../Img/cetke/ceramic-ion-gold-1.jpg")
  },
  {
    name: "Ceramic Ion Gold 2",
    image: require("../../../Img/cetke/ceramic-ion-gold-2.jpg")
  },
  {
    name: "Ceramic Ion White",
    image: require("../../../Img/cetke/ceramic-ion-white-1.jpg")
  },
  {
    name: "Ceramic Ion Thermal",
    image: require("../../../Img/cetke/ceramic-ion-thermal.jpg")
  },
  {
    name: "Eco Hair",
    image: require("../../../Img/cetke/eco-hair.jpg")
  },
  {
    name: "Fingerbrush",
    image: require("../../../Img/cetke/fingerbrush.png")
  },
  {
    name: "Healthy Hair 1",
    image: require("../../../Img/cetke/healthy-hair-1.png")
  },
  {
    name: "Healthy Hair 2",
    image: require("../../../Img/cetke/healthy-hair-2.jpg")
  },
  {
    name: "Heat Pro",
    image: require("../../../Img/cetke/heat-pro.png")
  },
  {
    name: "Holiday",
    image: require("../../../Img/cetke/holiday.png")
  },
  {
    name: "Iconic Boar",
    image: require("../../../Img/cetke/iconic-boar.png")
  },
  {
    name: "istyle",
    image: require("../../../Img/cetke/istyle.png")
  },
  {
    name: "Kerabrush",
    image: require("../../../Img/cetke/kerabrush.png")
  },
  {
    name: "Procontrol",
    image: require("../../../Img/cetke/procontrol.png")
  },
  {
    name: "Proforme",
    image: require("../../../Img/cetke/proforme.png")
  },
  {
    name: "Proforme Large",
    image: require("../../../Img/cetke/proforme-lg.png")
  },
  {
    name: "Protermal",
    image: require("../../../Img/cetke/protermal.png")
  },
  {
    name: "StyleUp",
    image: require("../../../Img/cetke/styleup.png")
  },
  {
    name: "Velours",
    image: require("../../../Img/cetke/velours.png")
  }
];

module.exports = photos;
