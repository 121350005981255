import React, { Fragment } from "react";

import HeadTags from '../Head/Tags';
import Manufacturer from "./Manufacturer/Manufacturer";

import "./Manufacturers.scss";

// manufacturers
import annyerLogo from "../../Img/manufacturers/annyer.png";
import diksonLogo from "../../Img/manufacturers/dikson.png";
import jaguarLogo from "../../Img/manufacturers/jaguar.jpg";
import moserLogo from "../../Img/manufacturers/moser.png";
import oliviaGardenLogo from "../../Img/manufacturers/olivia-garden.png";
import revlonLogo from "../../Img/manufacturers/revlon.png";
import subrinaLogo from "../../Img/manufacturers/subrina.png";
import wellaLogo from "../../Img/manufacturers/wella.png";

// manufacturers line
import emsibethLogo from "../../Img/manufacturers/emsibeth.png";
import luxinaLogo from "../../Img/manufacturers/luxina.png";
import macadamiaLogo from "../../Img/manufacturers/macadamia.png";
import maroccanoilLogo from "../../Img/manufacturers/maroccanoil.jpeg";
import marrakeshLogo from "../../Img/manufacturers/marrakesh.png";
import reuzelLogo from "../../Img/manufacturers/reuzel.jpg";
import osmoLogo from "../../Img/manufacturers/osmo.png";
import togethairLogo from "../../Img/manufacturers/togethair.jpg";

const Manufacturers = () => {
  return (
    <Fragment>
      <HeadTags title="Proizvođači | Frizer Pribor" />
      <section>
        <div className="container">
          <h1>
            <span className="b-purple-line">Proizvođači</span>
          </h1>
          <div className="row text-center">
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={annyerLogo}
                alt="Annyer"
                title="Annyer"
                products="Boje, šamponi, gelovi, lakovi i ostalo"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={diksonLogo}
                alt="Dikson"
                title="Dikson"
                products="Boje, šamponi, ampule, uljni gel i ostalo"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={jaguarLogo}
                alt="Jaguar"
                title="Jaguar"
                products="Mašinice, fenovi, škare"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={moserLogo}
                alt="Moser"
                title="Moser"
                products="Fenovi, mašinice"
              />
            </div>
          </div>
          <div className="row text-center">
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={oliviaGardenLogo}
                alt="Olivia Garden"
                title="Olivia Garden"
                products="Četke, škare, razne pregače i ostalo"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={revlonLogo}
                alt="Revlon"
                title="Revlon"
                products="Boje, scrunch za kovrče, lakovi, hidrogeni i ostalo"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={subrinaLogo}
                alt="Subrina"
                title="Subrina"
                products="Ehoton, boje, lakovi, kemikalije za trajnu, styling proizvodi"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={wellaLogo}
                alt="Wella"
                title="Wella"
                products="Boje, hidrogen, blajh"
              />
            </div>
          </div>
          <h2 className="other-manu">Ostali proizvođači:</h2>
          <p>
            Afrodita, Ermila, Euro-Stil, Keune, Kiepe, Matador, Placentinol,
            Remington, Wahl, Walera
          </p>
        </div>
      </section>
      <section>
        <div className="container">
          <h1>
            <span className="b-purple-line">Kozmetika</span>
          </h1>
          <div className="row text-center">
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={emsibethLogo}
                alt="Emsibeth"
                title="Emsibeth"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer img={luxinaLogo} alt="Luxina" title="Luxina" />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={macadamiaLogo}
                alt="Macadamia"
                title="Macadamia"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={maroccanoilLogo}
                alt="Maroccanoil"
                title="Maroccanoil"
              />
            </div>
          </div>
          <div className="row text-center">
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={marrakeshLogo}
                alt="Marrakesh"
                title="Marrakesh"
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer img={reuzelLogo} alt="Reuzel" title="Reuzel" />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer img={osmoLogo} alt="Osmo" title="Osmo" />
            </div>
            <div className="col-sm-6 col-md-3">
              <Manufacturer
                img={togethairLogo}
                alt="Togethair"
                title="Togethair"
              />
            </div>
          </div>
          <h2 className="other-manu">Ostala kozmetika:</h2>
          <p>Crazy color, Spicy Color, Mad Touch, Evin Rhose</p>
        </div>
      </section>
    </Fragment>
  );
};

export default Manufacturers;
