import React, { Fragment } from "react";

import "./Product.scss";

const Product = props => {
  return (
    <Fragment>
      <img className="img-center product" src={props.img} alt={props.alt} />
      <p className="product-name">{props.name}</p>
    </Fragment>
  );
};

export default Product;
