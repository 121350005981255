import React, { Component, Fragment } from "react";

import "./Main.scss";

import Navigation from "../Components/Navigation/Navigation";
import Footer from "../Components/Footer/Footer";

class Main extends Component {
  render() {
    return (
      <Fragment>
        <Navigation />
        <Footer />
      </Fragment>
    );
  }
}

export default Main;
