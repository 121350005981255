const photos = [
  {
    name: "Brijacka Stolica Duca",
    image: require("../../../Img/stolice/brijacka-stolica-duca.jpg")
  },
  {
    name: "Brijacka Stolica Lord",
    image: require("../../../Img/stolice/brijacka-stolica-lord.jpg")
  },
  {
    name: "Dulse",
    image: require("../../../Img/stolice/Dulse.jpg")
  },
  {
    name: "Funny",
    image: require("../../../Img/stolice/funny.jpg")
  },
  {
    name: "Ginger",
    image: require("../../../Img/stolice/Ginger.jpg")
  },
  {
    name: "Miki 1",
    image: require("../../../Img/stolice/Miki-1.jpg")
  },
  {
    name: "Miki 2",
    image: require("../../../Img/stolice/Miki-2.jpg")
  },
  {
    name: "Nogar 1",
    image: require("../../../Img/stolice/nogar-1.jpg")
  },
  {
    name: "Nogar 2",
    image: require("../../../Img/stolice/nogar-2.jpg")
  },
  {
    name: "Poltrona Bimbo Miro 1",
    image: require("../../../Img/stolice/Poltrona-bimbo-miro-1.jpg")
  },
  {
    name: "Poltrona Bimbo Miro 2",
    image: require("../../../Img/stolice/Poltrona-bimbo-miro-2.jpg")
  },
  {
    name: "Simona",
    image: require("../../../Img/stolice/Simona.jpg")
  }
];

module.exports = photos;
