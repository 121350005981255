const photos = [
  {
    name: "Gimmi",
    image: require("../../../Img/kolica/Gimmi.jpg")
  },
  {
    name: "Lotus",
    image: require("../../../Img/kolica/Lotus.jpg")
  },
  {
    name: "Orinoco",
    image: require("../../../Img/kolica/Orinoco.jpg")
  },
  {
    name: "Stolek",
    image: require("../../../Img/kolica/Stolek.jpg")
  },
  {
    name: "Terry 1",
    image: require("../../../Img/kolica/Terry-01.jpg")
  },
  {
    name: "Terry 2",
    image: require("../../../Img/kolica/Terry-02.jpg")
  }
];

module.exports = photos;
